<template>
  <!--begin::Production Calendar-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.PRODUCTION_CALENDAR_TITLE") }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.PRODUCTION_CALENDAR_DESC") }}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-lg-12 col-xl-12 mt-10">
          <p>{{ $t("MANAGEMENT.WORKING_TIME_PARAMS") }}
            <b-button class="btn btn-button btn-light btn-sm ml-4"
                      v-b-modal.modal-edit-working-time><Settings/>
            </b-button>
          </p>
        </div>
        <div class="col-lg-12 col-xl-12 mt-10">
          <p>{{ $t("MANAGEMENT.OFF_DAYS_OF_WEEK") }}</p>
          <EditHolidayModal/>
        </div>
        <div class="col-lg-12 col-xl-12 mt-10">
          <b-card>
            <b-card-text>
              {{ $t("MANAGEMENT.WEEKENDS_AND_HOLIDAYS") }}
              <span style="position:absolute; top: 5px; right: 5px">
                <b-icon icon="exclamation-circle-fill" style="width: 20px; height: 20px;" variant="primary"
                        v-b-tooltip.hover.v-dark="$t('MANAGEMENT.WEEKENDS_AND_HOLIDAYS_TOOLTIP')"></b-icon>
              </span>
            </b-card-text>
            <b-form-group>
              <b-form-tags input-id="tags-input"
                           placeholder=""
                           tag-variant="primary"
                           size="lg"
                           :add-button-text="$t('MANAGEMENT.WEEKENDS_AND_HOLIDAYS_ADD_BTN')"
                           add-button-variant="secondary"
                           v-b-tooltip.hover.bottom.v-dark="$t('MANAGEMENT.WEEKENDS_AND_HOLIDAYS_PLACEHOLDER')"
                           v-model="holidaysList"></b-form-tags>
            </b-form-group>
            <div class="w-100">
              <p class="float-right">
                <b-button
                    variant="light-primary"
                    class="float-left font-weight-bolder mr-2"
                    @click="resetModal">
                  {{ $t("MANAGEMENT.RESET_BUTTON") }}
                </b-button>
                <b-button
                    class="font-weight-bolder"
                    variant="primary"
                    @click="handleOk">
                  {{ $t("MANAGEMENT.APPLY_BUTTON") }}
                </b-button>
              </p>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Edit Working Time Modal-->
    <EditWorkingTime/>
    <!--end::Edit Working Time Modal-->
  </div>
  <!--end::Production Calendar-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_HOLIDAYS_LIST, PUT_HOLIDAYS_LIST } from "@/core/services/store/management/productionCalendar.module";

import Settings from "@/view/layout/svg/Settings";
import EditWorkingTime from "@/view/layout/modal/EditWorkingTime";
import EditHolidayModal from "@/view/layout/modal/EditHolidayModal";

export default {
  name: "ProductionCalendar",
  components: {
    Settings,
    EditWorkingTime,
    EditHolidayModal
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.PRODUCTION_CALENDAR_TITLE") }]);
    this.$store.dispatch(GET_HOLIDAYS_LIST);
  },
  data() {
    return {
    }
  },
  computed: {
    // ...mapGetters([
    //   "holidaysList"
    // ]),
    holidaysList: {
      get: function () {
        return this.$store.getters.holidaysList
      },
      set: function (newValue) {
        this.$store.state.holidaysList = newValue
      }
    },
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    }
  },
  methods: {
    resetModal() {
      window.location.reload();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.$store.state.holidaysList) {
        let dates = this.$store.state.holidaysList;
        this.$store.dispatch(PUT_HOLIDAYS_LIST, { dates });
      }
    }
  }
}
</script>

<style scoped>

</style>
