import { render, staticRenderFns } from "./EditHolidayModal.vue?vue&type=template&id=2e712966&scoped=true&"
import script from "./EditHolidayModal.vue?vue&type=script&lang=js&"
export * from "./EditHolidayModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e712966",
  null
  
)

export default component.exports