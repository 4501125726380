<template>
  <b-modal
      id="modal-edit-working-time"
      size="lg"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.WORKING_HOURS_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div v-for="item in productCalendar" class="row form-group">
        <label class="col-3 col-form-label">{{ setWeekDay(item.day) }}:</label>
        <div class="input-group col-3">
          <date-picker class="form-control" name="timePicker" :disabled="item.isHoliday" v-model="item.startTime" :config="options2"></date-picker>
          <div class="input-group-append">
           <span class="input-group-text">
            <span><i class="fas fa-clock"></i></span>
           </span>
          </div>
        </div>
        <div class="col-1">
          <div class="ml-3 pt-3 font-size-lg font-weight-bolder text-primary">&mdash;</div>
        </div>
        <div class="input-group col-3">
          <date-picker class="form-control" name="timePicker" :disabled="item.isHoliday" v-model="item.endTime" :config="options2"></date-picker>
          <div class="input-group-append">
           <span class="input-group-text">
            <span><i class="fas fa-clock"></i></span>
           </span>
          </div>
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              variant="secondary"
              class="float-left font-weight-bolder mr-2"
              @click="defaultModal">
            {{ $t("MANAGEMENT.RESET_BUTTON") }}
          </b-button>
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ $t("MANAGEMENT.APPLY_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import { GET_PRODUCTION_CALENDAR, PUT_PRODUCTION_CALENDAR } from "@/core/services/store/management/productionCalendar.module";

export default {
  name: "EditWorkingTime",
  mounted() {
    this.$store.dispatch(GET_PRODUCTION_CALENDAR);
  },
  data() {
    return {
      options2: {
        locale: i18nService.getActiveLanguage(),
        format: 'HH:mm',
        useCurrent: true,
        showClear: true,
        showClose: true
      },
      weekData: [
        {
          id: 'monday',
          name: this.$t("WEEK_DAYS.MONDAY")
        },
        {
          id: 'tuesday',
          name: this.$t("WEEK_DAYS.TUESDAY")
        },
        {
          id: 'wednesday',
          name: this.$t("WEEK_DAYS.WEDNESDAY")
        },
        {
          id: 'thursday',
          name: this.$t("WEEK_DAYS.THURSDAY")
        },
        {
          id: 'friday',
          name: this.$t("WEEK_DAYS.FRIDAY")
        },
        {
          id: 'saturday',
          name: this.$t("WEEK_DAYS.SATURDAY")
        },
        {
          id: 'sunday',
          name: this.$t("WEEK_DAYS.SUNDAY")
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      "productCalendar"
    ]),
  },
  methods: {
    setWeekDay(dayName) {
      let rightDay = this.weekData.find(item => { return item.id === dayName });
      return rightDay.name;
    },
    resetModal() {
      this.$bvModal.hide('modal-edit-working-time');
    },
    defaultModal() {
      const days = [
        {
          day: 'monday',
          isHoliday: false,
          startTime: '08:00',
          endTime: '20:00',
        },
        {
          day: 'tuesday',
          isHoliday: false,
          startTime: '08:00',
          endTime: '20:00',
        },
        {
          day: 'wednesday',
          isHoliday: false,
          startTime: '08:00',
          endTime: '20:00',
        },
        {
          day: 'thursday',
          isHoliday: false,
          startTime: '08:00',
          endTime: '20:00',
        },
        {
          day: 'friday',
          isHoliday: false,
          startTime: '08:00',
          endTime: '20:00',
        },
        {
          day: 'saturday',
          isHoliday: true,
          startTime: '08:00',
          endTime: '20:00',
        },
        {
          day: 'sunday',
          isHoliday: true,
          startTime: '08:00',
          endTime: '20:00',
        }
      ];
      this.$store.dispatch(PUT_PRODUCTION_CALENDAR, { days });
      this.$bvModal.hide('modal-edit-working-time');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      const entries = Object.entries(this.productCalendar);
      let days = [];
      entries.forEach(item => {
        days.push({...item[1]});
      })
      this.$store.dispatch(PUT_PRODUCTION_CALENDAR, { days });
      this.$bvModal.hide('modal-edit-working-time');
    }
  }
}
</script>

<style scoped>

</style>
