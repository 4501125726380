<template>
  <div class="row align-items-center">
    <div class="col-lg-7 col-xl-7">
      <b-card>
        <div v-for="item in productCalendar"  class="row form-group">
          <label class="col-9 col-form-label">{{ setWeekDay(item.day) }}:</label>
          <div class="col-3 pt-1">
          <span class="switch">
            <label>
              <input type="checkbox" @click="switchHoliday(item)" :checked="item.isHoliday" name="select"/>
              <span></span>
            </label>
          </span>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PUT_MAKE_HOLIDAY, PUT_MAKE_JOB_DAY } from "@/core/services/store/management/productionCalendar.module";

export default {
  name: "EditHolidayModal",
  data() {
    return {
      weekData: [
        {
          id: 'monday',
          name: this.$t("WEEK_DAYS.MONDAY")
        },
        {
          id: 'tuesday',
          name: this.$t("WEEK_DAYS.TUESDAY")
        },
        {
          id: 'wednesday',
          name: this.$t("WEEK_DAYS.WEDNESDAY")
        },
        {
          id: 'thursday',
          name: this.$t("WEEK_DAYS.THURSDAY")
        },
        {
          id: 'friday',
          name: this.$t("WEEK_DAYS.FRIDAY")
        },
        {
          id: 'saturday',
          name: this.$t("WEEK_DAYS.SATURDAY")
        },
        {
          id: 'sunday',
          name: this.$t("WEEK_DAYS.SUNDAY")
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      "productCalendar"
    ]),
  },
  methods: {
    setWeekDay(dayName) {
      let rightDay = this.weekData.find(item => { return item.id === dayName });
      return rightDay.name;
    },
    switchHoliday(item) {
      if (item.isHoliday) {
        this.$store.dispatch(PUT_MAKE_JOB_DAY, { day: item.day });
      } else {
        this.$store.dispatch(PUT_MAKE_HOLIDAY, { day: item.day });
      }
    }
  }
}
</script>

<style scoped>

</style>
